@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//Customize colors
@import "colors";

// Custom Mixins
@import "mixins";
//
// Banner
//

.banner {
    @include media-breakpoint-up(lg) {
        position: relative;
        overflow: hidden;
    }
}

.banner-image {
    @include media-breakpoint-up(lg) {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: aliceblue;

        .banner-img {
            @include image-object-cover();
            object-position: left;

            @media (min-width: 1125px) {
                object-position: center;
            }
        }
    }
}

.banner-text {
    @include media-breakpoint-down(md) {
        padding: 3rem 0;
        background-color: $white;
    }

    @include media-breakpoint-up(lg) {
        position: relative;
        margin: 2.125rem 0;
        z-index: 2;
    }

    @include media-breakpoint-up(xl) {
        margin: 4.125rem 0;
    }
}

.banner-text-wrapper {
    > *:last-child {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        padding: 3rem;
        background-color: $white;
        box-shadow: $box-shadow;
    }

    @include media-breakpoint-up(xl) {
        padding: 3.3rem;
    }
}
